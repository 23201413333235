import React from 'react';
import styled, { css } from 'styled-components';
import { Container } from 'react-bootstrap';
import {ImGithub, ImYoutube, ImLinkedin2, ImTwitter } from 'react-icons/im';
import * as classes from '../custom-styles/Footer.module.css';

const LinksContainer = styled.div`
  & > a:not(:last-child) {
    margin-right: 5px;
  }
`;

const FooterLink = styled.a`
  text-decoration: none;
  color: #212529;
  font-size: 0.75rem;
  font-weight: 500;
`;

const SocialComponent = styled.div`
  margin: 10px 15px;
`;

const ImLinkedinicon = styled(ImLinkedin2)`
  color: #0073b1;
  font-size: 24px;
`;

const ImTwittericon = styled(ImTwitter)`
  color: #1bb1e4;
  font-size: 24px;
`;

const ImYoutubeicon = styled(ImYoutube)`
  color: #f70000;
  font-size: 24px;
`;
const ImGitHubicon = styled(ImGithub)`
  color: #202020;
  font-size: 24px;
`;
const Footericons = styled.div`
  display: flex;
  justify-content: center;
  // margin-right:30px;
  & a {
    margin-left: 15px;
  }
`;

const Copyrightext = styled.div`
  text-align: end;
`;

export default function Footer() {
  return (
    <>
      <div className={`${classes.bg_privacy_policy}`}>
        <Container className="py-2">
          <div className={`${classes.footer_data}`}>
            <LinksContainer className="px-3">
              <div className="my-1">
                <FooterLink href="/console/about">About Us</FooterLink>
              </div>
              <div className="my-1">
                <FooterLink href="/docs/">Resources</FooterLink>
              </div>
              <div className="my-1">
                <FooterLink href="/console/privacy">Privacy Policy</FooterLink>
              </div>
              <div className="my-1">
                <FooterLink href="/console/terms">
                  Terms and Conditions
                </FooterLink>
              </div>
            </LinksContainer>
            <div className="text-center">
              <FooterLink href="/contact" className="px-3">
                Contact Us
              </FooterLink>
              <SocialComponent>
                <Footericons>
                  <a
                    href="https://www.linkedin.com/company/ametnes/"
                    target={'_blank'}
                  >
                    <ImLinkedinicon />
                  </a>
                  <a href="https://twitter.com/ametnes" target={'_blank'}>
                    <ImTwittericon />
                  </a>
                  <a
                    className=""
                    href="https://www.youtube.com/@ametnescloud"
                    target={'_blank'}
                  >
                    <ImYoutubeicon />
                  </a>
                  <a
                    className=""
                    href="https://github.com/ametnes/"
                    target={'_blank'}
                  >
                    <ImGitHubicon />
                  </a>
                </Footericons>
              </SocialComponent>
            </div>
            <Copyrightext>
              <h6 className={`${classes.font12} m-0 px-3`}>
                Copyright © 2025 Ametnes, Inc
              </h6>
            </Copyrightext>
          </div>
        </Container>
      </div>
    </>
  );
}
